const colors = {
  transparent: 'transparent',
  white: '#FFFFFf',
  black: '#181E25',
  gray: {
    25: '#EEEFF2',
    50: '#F3F5F7',
    100: '#E7EAF0',
    200: '#CFD6DF',
    300: '#ACB8C4',
    400: '#94A2B2',
    500: '#67788E',
    600: '#495A6E',
    700: '#384656',
    800: '#29333D',
    900: '#181E25'
  },
  blue: {
    50: '#E3E5FB',
    100: '#B6BAF8',
    200: '#888FF4',
    300: '#5B64F0',
    400: '#2E39EC',
    500: '#0509F8',
    600: '#0408E0',
    700: '#0307C8',
    800: '#0206B0',
    900: '#010598'
  },
  red: {
    '50': '#fef2f2',
    '100': '#fee2e2',
    '200': '#fecaca',
    '300': '#fca5a5',
    '400': '#f87171',
    '500': '#ef4444',
    '600': '#dc2626',
    '700': '#b91c1c',
    '800': '#991b1b',
    '900': '#7f1d1d'
  },
  whatsapp: {
    300: '#dcf8c6',
    500: '#25D366',
    600: '#22C057',
    800: '#128c7e'
  }
}

export { colors }
